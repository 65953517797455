import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "visualizing-data-with-react-and-d3js"
    }}>{`Visualizing data with React and d3.js`}</h1>
    <Vimeo id={424598200} mdxType="Vimeo" />
    <p>{`Let's talk about how React and D3 fit together.`}</p>
    <p>{`The initial chapters get you up to speed and teach you the mental models. Later on we apply them in various scenarios.`}</p>
    <p>{`Focus on the building blocks first. Use the bigger projects as ideas to play around with. A practice ground if you will :)`}</p>
    <p>{`This initial section has five core chapters:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/d3-quick-intro"
        }}>{`D3 the good parts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/react-d3"
        }}>{`How React makes D3 easier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/react-d3/2"
        }}>{`When should you use an existing library? Which one?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/building-blocks/1"
        }}>{`Quickly integrate any D3 code in your React project with Blackbox Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/building-blocks/3"
        }}>{`Build scalable dataviz components with full integration`}</a></li>
    </ul>
    <p>{`That gives you the 80/20 rule right there.`}</p>
    <p>{`Dig through those 5 chapters and you get 80% of the value. Because your time is valuable and I want you to build stuff, not grind theory ❤️`}</p>
    <p>{`When you're ready, I encourage you to have fun with the examples and the cookbook section in particular. That's where I've been adding new projects every few months.`}</p>
    <p>{`If you build every example in this course, you'll get:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/building-blocks"
        }}>{`A few small components in Codesandbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tech-salaries/choropleth-map/"
        }}>{`A choropleth map`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tech-salaries/salaries-histogram"
        }}>{`An interactive histogram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/animation/ball-game"
        }}>{`A bouncing ball`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/animation/simple-animation"
        }}>{`A swipe transition`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/animation/enter-update-exit"
        }}>{`An animated alphabet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/animation/redux-animation"
        }}>{`A simple particle generator with Redux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/speed-optimizations/smooth-animation"
        }}>{`A particle generator pushed to 20,000 elements with canvas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/speed-optimizations/canvas-game"
        }}>{`Billiards simulation with MobX and canvas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/animation/game-loops"
        }}>{`A circle graph that transitions to a bar graph`}</a></li>
    </ul>
    <p>{`Looks random, right? Bear with me. Examples build on each other.`}</p>
    <p>{`The first few examples teach you about static data visualizations and the
basics of merging React and D3 using two different approaches.`}</p>
    <p>{`The choropleth and histogram visualizations teach you about interactivity and
components working together.`}</p>
    <p>{`You learn about game loop and transition animations through two types of
bouncing balls. Followed by more complex enter/exit transitions with an
animated alphabet.`}</p>
    <p>{`We look at how far we can push React's performance with a simple particle
generator and a dancing fractal tree. A billiards game helps us learn about
complex canvas manipulation.`}</p>
    <p>{`Throughout these examples, we're going to use `}<strong parentName="p">{`React 16`}</strong>{`, probably compatible with
`}<strong parentName="p">{`React 17`}</strong>{`, `}<strong parentName="p">{`D3v5`}</strong>{`, and modern `}<strong parentName="p">{`ES6+`}</strong>{` JavaScript syntax. The particle
generator also uses `}<strong parentName="p">{`Redux`}</strong>{` for the game loop and the billiards simulation
uses MobX. We use `}<strong parentName="p">{`Konva`}</strong>{` for complex canvas stuff.`}</p>
    <p>{`That way you can build experience in large portions of the React ecosystem so
you can choose what you like best.`}</p>
    <p>{`Don't worry, if you're not comfortable with modern JavaScript syntax just yet.
By the end of this book, you're gonna love it!`}</p>
    <p>{`Until then, here's an interactive cheatsheet:
`}<a parentName="p" {...{
        "href": "https://es6cheatsheet.com/"
      }}>{`es6cheatsheet.com`}</a>{`. It uses runnable code samples
to compare the ES5 way with the ES6 way so you can brush up quickly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      